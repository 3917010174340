import('./bootstrap.js');

import {createApp, h} from 'vue'
import vuetify from './plugins/vuetify.js';
import {createInertiaApp} from '@inertiajs/vue3'
import UserCanMixin from './Shared/Mixins/UserCan';
import * as Sentry from "@sentry/vue";
import {resolvePageComponent} from 'laravel-vite-plugin/inertia-helpers';
import '../sass/Core/app.scss';
import '../sass/Mailings/MailBuilderPreview.scss';
import '../sass/Microsites/FormsDesignerPreview.scss';
import '../sass/app.scss';

createInertiaApp({
    resolve: name => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
    setup({el, App, props, plugin}) {
        const vue = createApp({
            render: () => h(App, props),

            data() {
                return {
                    currentPage: 0,
                }
            },

            methods: {
                detectDarkmode: function () {
                    let isDarkMode, isLightMode;

                    if (this.$inertia.page.props.auth.user === null) {

                    } else if (this.$inertia.page.props.auth.user.appearance === 'device') {
                        isDarkMode = window.matchMedia("(prefers-color-scheme: dark)").matches
                        isLightMode = window.matchMedia("(prefers-color-scheme: light)").matches

                        window.matchMedia("(prefers-color-scheme: dark)").addListener(e => e.matches && this.activateDarkMode());
                        window.matchMedia("(prefers-color-scheme: light)").addListener(e => e.matches && this.activateLightMode());
                    } else if (this.$inertia.page.props.auth.user.appearance === 'light') {
                        isDarkMode = false;
                        isLightMode = true;
                    } else if (this.$inertia.page.props.auth.user.appearance === 'dark') {
                        isDarkMode = true;
                        isLightMode = false;
                    }

                    if (isDarkMode) this.activateDarkMode();
                    if (isLightMode) this.activateLightMode();
                },

                activateDarkMode: function () {
                    this.$vuetify.theme.dark = true;
                },

                activateLightMode: function () {
                    this.$vuetify.theme.dark = false;
                }
            },

            mounted() {
                this.detectDarkmode();
            },
        })
            .use(plugin)
            .use(vuetify)
            .mixin({methods: {route: (...args) => window.route(...args)}})
            .mixin(UserCanMixin)
            .mount(el);

        Sentry.init({
            vue,
            dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
            trackComponents: true,
            environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
            release: import.meta.env.VITE_SENTRY_RELEASE,
        });
    },
});
